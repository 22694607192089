import React, { useContext, useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import Slider from "react-slick"

import { StateController } from './../../App'
import AwardBox from '../molecules/AwardBox'
import { itsFiltered }  from '../../utils/functions'

function AwardsGallery({
  awardBoxType,
  itemsPerLine,
  limit,
  limitButtonText,
  list,
  canRedem,
  termText,
  termLink,
  termModal,
  redemtionText,
  steps,
  showStock,
  download,
  twoStepAuth,
  setAward_exhausted
}) {
  const {context} = useContext(StateController)
  const [opts, setOpts] = useState([])
  const [currentGallery, setCurrentGallery] = useState(false)

  console.log("Context redemtions:", list)

  useEffect(() => {
    if (context && context.awards) {
      setOpts(
        Object.keys(context.awards).map((key) => context.awards[key])
      )
    }
  }, [context])
  useEffect(() => {

    if(list.length === 0){
      if (setAward_exhausted) {
        setAward_exhausted(true)
      }
    }
  }, [context])

  const stepper_settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    customPaging: function(i) {
      return (
        <span key={i}>
          <p>{`Mes ${i+1}`}</p>
        </span>
      )
    }
  }

  const galleryToggler = (e) => {
    setCurrentGallery(e.target.value)
  }

  return (
    <div className={`mc-awards-container mc-awards-column-${itemsPerLine}`}>

      {context && context.allAwards && context.allAwards[0] && 
        <div className="mc-awards-container__selector">
          <select name="gallery-selector" id="gallery-selector" value={currentGallery} onChange={galleryToggler}>
            <option value="">Filtrar por valor</option>
            {itsFiltered(20000, context.availableForRedemtion) && <option value="20000">$20.000</option>}

          </select>
        </div>
      }
        
      {steps
        ? <div className="mc-awards-container__steper">
            <Slider {...stepper_settings}>
              {opts.map((step, index) => {

                return (
                  <div className="mc-awards-container__items" key={index}>
                    {step.awards.map((item, index) => {
                      
                      return (
                        <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                          <AwardBox
                            type={awardBoxType}
                            award={item}
                            canRedem={step.canRedem ? step.canRedem : false}
                            termLink={termLink}
                            termText={termText}
                            termModal={termModal}
                            redemtionText={redemtionText}
                          />
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Slider>
          </div>
        : list && list[0] 
        ? <div className="mc-awards-container__items">
            {list.filter((item, i) => {

              

              return limit ? i < limit ? true : false : true
            }).map((item, index) => {


              if (download && item.prepurchased === 1) item.prepurchased = true
              if (download && item.prepurchased === 0) item.prepurchased = false

              const findAward = context.stock.find((it) => it.id === item.id && it.Valor === item.price)
              if (findAward && findAward.Restantes === 0) {
                return false
              }

              return (
                <div key={index} className={`mc-awards-container__items--item type-${awardBoxType}`}>
                  <AwardBox
                    index={index}
                    type={awardBoxType}
                    award={item}
                    canRedem={canRedem}
                    termLink={termLink}
                    termText={termText}
                    termModal={termModal}
                    redemtionText={redemtionText}
                    showStock={showStock}
                    download={download}
                    twoStepAuth={twoStepAuth}
                  />
                </div>
              )
            })}
          </div>
        : <>No hay premios</>
      }

      {limit && limitButtonText && (list && list[0]) && 
        <div className="mc-awards-container__actions">
          <Link to="/premios">{limitButtonText}</Link>
        </div>
      }
    </div>
  )
}

export default AwardsGallery