import React, { useContext, useEffect, useState } from 'react'

import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'
import MajorAwardBanner from '../atoms/MajorAwardBanner'

import { getMaximumBlock } from '../../utils/functions'
import CustomModal from '../molecules/CustomModal'

function Awards() {
  const [awards, setAwards] = useState(false)
  const [canRedem, setCanRedem] = useState(false)
  const {context} = useContext(StateController)
  const [award_exhausted, setAward_exhausted] = useState(false);
  
  
  useEffect(() => {
    if (context && context.awards) {
      setAwards(
        context.awards[`block_${getMaximumBlock(1, context.blocks)}`].awards
      )
      setCanRedem(
        context.awards[`block_${getMaximumBlock(1, context.blocks)}`].canRedem
      )
    }
  }, [context])

  console.log('award_exhausted',award_exhausted)

  return (
    <div className='mc-page awards inner slow_ani'>

      <Container>
        <h1>Catálogo de <span>premios</span></h1>
        {context &&
          <p>
            {context.awards[`block_1`].canRedem
              ? '¡Tu bono digital está listo para ser canjeado!'
              : 'Aún no has alcanzado tu meta. El botón para canjear tu bono estará disponible cuando lo hagas.'
            }
          </p>
        }
      </Container>

      <Container>
        
        {awards 
          ? <AwardsGallery
              setAward_exhausted={setAward_exhausted}
              awardBoxType={2}
              itemsPerLine={3}
              limit={false}
              limitButtonText="Ver más comercios"
              showStock={false}
              list={awards}
              steps={false}
              canRedem={canRedem}
              twoStepAuth={false}
              redemtionText="Canjear bono"
              termText='Este bono está sujeto a los <a href="/terminos-y-condiciones">términos y condiciones</a> del comercio aliado.</a>'
              termLink="/terminos-y-condiciones"
            />
          : <Preloader
              type="section"
              invert
            />
        }

        <MajorAwardBanner context={context} />
      </Container>
{
  award_exhausted &&
      <CustomModal
        global
        title="Bonos agotados"
        desc="Ya no te quedan bonos para redimir, pero sigue pagando con tu Tarjeta de Débito Mastercard PacifiCard."
        cta="Aceptar"
        icon={false}
        primaryAction={
          {
            action: setAward_exhausted,
            buttonText: 'Cerrar',
            loading: false
          }
        }
        show={award_exhausted} setShowModal={setAward_exhausted}
      />
}
    </div>
  )
}
export default Awards