import React from 'react'
import Container from "../../layouts/Container/Container";

const Preheader = () => {
  return (
    <section className='Preheader'>
      <Container>

        <article>
          <h3>Si cumpliste tu meta, <strong>canjea ya tu bono antes de que se agoten.</strong></h3>
          <p>*Cantidades limitadas.</p>
        </article>
      </Container>
    </section>
  )
}

export default Preheader
